<template>
    <div>
        <header>
            <ul>
                <li>
                    <img src="../../icon/back_black.png" class="title_icon" alt="" @click="tui">
                </li>
                <li class="li2">成绩单</li>
                <li>
                    <img v-show="false" class="title_icon" src="../../icon/share.png">
                </li>
            </ul>
        </header>

        <div class="blm">
            <div class="tou1">
                <div class="bl">{{ lessonInfo.lesson_name }}</div>
                <div class="mf">满分：<span>{{ lessonInfo.full_sum_score }}分</span>
                    及格：<span>{{ lessonInfo.pass_score }}分</span></div>
            </div>
            <div class="xiada">
                <div class="xia">
                    <p>
                        用户名：<span style="font-size: 16px; color: #000">{{ lessonInfo.real_name }}</span>
                    </p>
                    <p style="margin-top: 7px">
                        班级排名：<span style="color: red">{{ class_sort }}</span>
                    </p>
                    <p style="margin-top: 7px">
                        班级人数：<span style="color: red">{{ class_student_num }}</span>
                    </p>
                </div>
                <div class="xiayou">
                    <p style="font-size: 39px; color: red">{{ lessonInfo.sum_score }}</p>
                    <p>总成绩</p>
                </div>
            </div>
        </div>
        <div class="hui"></div>
        <div class="blm">
            <div class="tou1">
                <div class="bl">视频/直播得分</div>
                <div class="mf">满分：<span>{{ lessonInfo.full_live_video_score }}分</span> 得分：<span>{{ lessonInfo.live_video_long_time }}分</span>
                </div>
            </div>
            <div class="xia1">
                <p>共有视频/直播时长：<span>{{ lessonInfo.live_video_long_time }}</span>小时 学完：<span>{{ lessonInfo.study_live_video_long_time }}</span>小时
                </p>
            </div>
        </div>
        <div class="hui"></div>
        <div class="blm">
            <div class="tou1">
                <div class="bl">随堂评测</div>
                <div class="mf">满分：<span>{{ lessonInfo.full_class_test_score }}分</span> 得分：<span>{{ lessonInfo.class_test_exercise_score }}分</span>
                </div>
            </div>
            <div class="xia1">
                <p>共有：<span>{{ lessonInfo.class_test_exercise_num }}</span>题 答对：<span>{{ lessonInfo.correct_class_test_exercise_num }}</span>题
                </p>
            </div>
        </div>
        <div class="hui"></div>
        <div class="blm1">
            <div class="tou1">
                <div class="bl">作业</div>
                <div class="mf">满分：<span>{{ lessonInfo.full_task_score }}分</span>
                    得分：<span>{{ lessonInfo.task_score }}分</span></div>
            </div>
            <div class="table_content">
                <table class="table_content_title tabs">
                    <tr class="diyitr">
                        <th>作业</th>
                        <th>得分</th>
                    </tr>
                    <tr v-for="(item,index) in testList" :key="index">
                        <td class="td">第{{ item.sort }}次作业</td>
                        <td class="td">{{ item.score }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="hui"></div>
        <div class="blm1">
            <div class="tou1">
                <div class="bl">考试</div>
                <div class="mf1">满分：<span> {{lessonInfo.full_exam_paper_score}}分</span></div>
            </div>
            <div class="table_content">
                <table v-if="exam_paper_list.length<1" class="table_content_title tabs">
                    <tr class="diyitr">
                        <th>试卷</th>
                        <th>得分</th>
                    </tr>
                    <tr>
                        <td class="td">暂无数据</td>
                        <td  class="td">暂无数据</td>
                    </tr>
                </table>
                <table v-else class="table_content_title tabs">
                    <tr class="diyitr">
                        <th>作业</th>
                        <th>得分</th>
                    </tr>
                    <tr v-for="(item,index) in exam_paper_list" :key="index">
                        <td  class="td">{{ item.exam_name }}</td>
                        <td  class="td">{{ item.score }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {get_achievement} from '@/utils/index1.js'

    export default {
        data() {
            return {
                user_name: '',
                full_sum_score: 0,
                pass_score: 0,
                sum_score: 0,
                full_live_video_score: 0,
                live_video_long_time: 0,
                study_live_video_long_time: 0,
                lessonInfo: {},
                class_sort: 0,
                class_student_num: 0,
                testList: [],
                exam_paper_list: [],
            }
        },
        mounted() {
            this.get_msg()
        },
        methods: {
            tui() {
                this.$router.go(-1)
            },
            get_msg() {
                var params = {
                    class_id: this.$route.query.class_id,
                    student_id: this.$route.query.student_id
                }
                get_achievement(params).then(res => {
                    this.lessonInfo = res.student_info
                    this.class_sort = res.class_sort
                    this.class_student_num = res.class_student_num
                    this.testList = res.task_list
                    this.exam_paper_list = res.exam_paper_list
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .diyitr {
        height: 88px;

        th {
            background: rgb(238, 238, 238);
            height: 70px;
            font-size: 28px;
        }
    }

    .tabs {
        margin: 0 auto;
        height: 400px;

        tr {
            td:nth-child(1) {
                color: rgb(161, 161, 161);
            }

            td:nth-child(2) {
                color: red;
            }
        }
    }

    /*设置奇数行颜色*/
    table tr:nth-child(odd) {
        background: rgb(248, 248, 248);
    }

    /*设置偶数行颜色*/
    table tr:nth-child(even) {
        background: rgb(255, 255, 255);
    }

    /*设置鼠标聚焦到行上是凸显*/
    // table tr:hover {
    //   background-color: rgb(121, 121, 121);
    // }
    .xia1 {
        width: 100%;
        height: 100px;
        line-height: 100px;
        // background: seagreen;
        color: rgb(161, 161, 161);
        font-size: 28px;

        p {
            padding-left: 25px;
        }
    }

    .hui {
        width: 100%;
        height: 20px;
        background: rgb(245, 245, 245);
    }

    .xiayou {
        width: 150px;
        height: 100%;
        // background: rosybrown;
        text-align: center;
    }

    .xiada {
        width: 100%;
        height: 140px;
        // background: turquoise;
        display: flex;
        justify-content: space-between;
    }

    .xia {
        width: 300px;
        height: 140px;
        // background: royalblue;
        margin-left: 25px;

        p {
            color: rgb(161, 161, 161);
        }
    }

    .mf {
        height: 100%;
        // background: #000;
        color: rgb(161, 161, 161);
        margin-right: 25px;

        span {
            color: red;
            font-weight: 600;
            font-size: 30px;
        }
    }

    .mf1 {
        height: 100%;
        color: rgb(161, 161, 161);

        span {
            color: red;
            font-weight: 600;
            font-size: 30px;
        }
    }

    .bl {
        width: 300px;
        height: 100%;
        // background: red;
        font-size: 34px;
        font-weight: 700;
        margin-left: 25px;
        overflow: hidden;
    }

    .tou1 {
        width: 100%;
        height: 120px;
        line-height: 120px;
        // background: sandybrown;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .blm {
        width: 96%;
        height: 300px;
        // background: salmon;
        margin: 0 auto;
        margin-top: 2px;
    }

    .blm1 {
        width: 96%;
        min-height: 540px;
        // background: salmon;
        margin: 0 auto;
        margin-top: 2px;
    }

    .li2 {
        width: 600px;
    }

    header {
        width: 100%;
        height: 88px;
        background: rgb(255, 255, 255);
        border-bottom: 1px solid rgb(241, 235, 235);
        .title_icon{
            width: 68px;
            height: 68px;
            margin-top:10px;
        }
        ul {
            width: 100%;
            height: 88px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            text-align: center;
            font-size: 36px;
            color: #222222;
            letter-spacing: 0;

            li {
                width: 200px;
            }
        }
    }

    .table_content {
        width: 100%;
        border-color: #f2f2f2;
        text-align: center;

        .table_content_title {
            width: 93%;
            border: none;
            height: 32px;
        }
    }
    .td{
        height: 72px;
    }
</style>
